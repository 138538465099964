import { post, get } from "@/request/https";
/**
 * 获取新闻列表
 * @param {Object} data
 * @returns Promise
 */
export const getNewsList = (data) => {
    return get("news/page", data);
};


/**
 * 根据pid获取新闻专题列表
 * @param {Object} data
 * @returns Promise
 */
export const getNewsTopics = (data) => {
    return get("news/topic/list", data);
};


/**
 * 文字反馈
 * @param {Object} params
 * @returns Promise
 */
export const wordTopic = (params) => {
    return post("feedback/textFeedback", params);
};

/**
 * 视频反馈
 * @param {Object} params
 * @returns Promise
 */
export const videoTopic = (params) => {
    return post("feedback/videoFeedback", params);
};

/**
 * 音频反馈
 * @param {Object} params
 * @returns Promise
 */
export const audioTopic = (params) => {
    return post("feedback/audioFeedback", params);
};

/**
 * 人大代表登录
 * @param {Object} params
 * @returns Promise
 */
export const behlfLogin = (params) => {
    return post("delegate/login", params);
};

/**
 * 人大代表关联反馈列表
 * @param {Object} params
 * @returns Promise
 */
export const behlfDelegateList = (data) => {
    return get("delegate/feedback", data);
};

/**
 * 人大代表-在线接访
 */
export const visitsbythecongress = (params) => {
    return post("delegate/receiveVisit", params);
};

/**
 * 人大代表处理反馈
 * @param {Object} params
 * @returns Promise
 */
export const handleFeedback = (params) => {
    return post("delegate/handleFeedback", params);
};


/**
 * 反馈公示分页列表
 * @param {Object} params
 * @returns Promise
 */
export const feedbackPublicPage = (data) => {
    return get("feedback/publicPage", data);
};

/**
 * 代表信息列表
 * @param {Object} params
 * @returns Promise
 */
export const delegateList = (data) => {
    return get("delegate/page", data);
};

/**
 * 代表分类树形图
 * @param {Object} params
 * @returns Promise
 */
export const delegateTypeList = (data) => {
    return get("delegate/type/tree", data);
};

/**
 * 字典项下拉筛选
 * @param {Object} params
 * @returns Promise
 */
export const getDicScreen = (data) => {
    return get("dic/item/screen", data);
};



/**  新增**\

/**
 * 心愿申请-添加
 * 
 */
export const wishApplicationAdd = (params) => {
    return post("wish/apply/add", params);
};

/**
 * 心愿申请-分页列表
 * 
 */
export const wishapplicationpaginationlist = (data) => {
    return get("wish/apply/page", data);
};

/**
 * 心愿申请-审核
 * 
 */
export const wishapplicationAuditing = (params) => {
    return post("wish/apply/approve", params);
};

/**
 * 心愿-分页列表
 * 
 */
export const wishPaginationList = (data) => {
    return get("wish/page", data);
};

/**
 * 心愿-领取
 * 
 */
export const wishClaim = (params) => {
    return post("wish/receive", params);
};


/**
 * 心愿-完成
 * 
 */
export const wishCompleted = (params) => {
    return post("wish/complete", params);
};



/**
 * 心愿领取记录-分页列表
 * 
 */
export const WishCollectionRecordPaginationList = (data) => {
    return get("wish/receive/log/page", data);
};




/**
 * 意见收集站-分页列表
 * 
 */
export const opinionPaginationList = (data) => {
    return get("opinion/collect/station/page", data);
};


/**
 * 意见收集站-提交意见
 */
export const CommentsSubmitComments = (params) => {
    return post("opinion/collect/station/subOpinion", params);
};

/**
 * 接访群众预安排-分页列表
 */
export const prearrangementlist = (data) => {
    return get("receive/visit/arrange/page", data);
};
